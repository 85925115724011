import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";

import FamilyOverviewForm from "entities/Family/components/OverviewForm";

import theme from "theme";

import { URLS } from "config/urls";
import useIncomeEligibleFlowNavigation from "../hooks";

const OverviewPage = () => {
  const { t } = useTranslation();

  const { navigateNext, readyToNavigate, backUrl } = useIncomeEligibleFlowNavigation();

  return (
    <FullPageDialog
      title={t("overview")}
      backUrl={backUrl}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack
          spacing={2}
          sx={{ height: "100%", justifyContent: "space-between" }}
        >
          <div style={{ flex: 1 }}>
            <FamilyOverviewForm />
          </div>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {readyToNavigate && (
              <BasicButton label={t("next")} onClick={navigateNext} />
            )}
          </div>
        </Stack>
      </Container>
    </FullPageDialog>
  );
};
export default OverviewPage;
