import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import AddButton from "components/AddButton";
import Link from "components/Link";
import IconButton from "components/IconButton";

import theme, { colors } from "theme";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import { useMyFamily } from "entities/Family/sdk";

import { EditIcon } from "icons";
import useIncomeEligibleFlowNavigation from "../hooks";

const ChildrenListPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const { navigateNext, backUrl } = useIncomeEligibleFlowNavigation();

  if (!familyData || !voucherIdParam) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("children")}
      backUrl={backUrl}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("addChildrenInstruction")}
            </Text>
            <Text component="p" variant="bodyMedium" color={colors.onSurface}>
              {t("pleaseInclude")}
            </Text>
            <ul>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeChildrenUnder18")}
                </Text>
              </li>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeChildrenUnder24InSchool")}
                </Text>
              </li>
            </ul>
          </div>
          <Stack gap={theme.spacing(2)} flex={1} alignItems="center">
            {familyData.children.map((child) => (
              <Stack
                direction="row"
                key={child.id}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderColor: colors.outline,
                  padding: theme.spacing(1),
                  width: "100%",
                }}
              >
                <Stack gap="15px">
                  <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                    {`${child.first_name} ${child.last_name}`.trim() === "" &&
                      "Unknown name"}
                    {child.first_name} {child.last_name}
                  </Text>
                </Stack>
                <Link
                  to={reverse(URLS.VERIFY_INCOME_EDIT_CHILD, {
                    childId: child.id,
                    voucherId: voucherIdParam,
                  })}
                >
                  <IconButton
                    sx={{
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Link>
              </Stack>
            ))}
            <Link
              to={reverse(URLS.VERIFY_INCOME_ADD_CHILD, {
                voucherId: voucherIdParam,
              })}
            >
              <AddButton
                label={t("addChild")}
                backgroundColor={colors.secondaryContainer}
              />
            </Link>
          </Stack>
        </Container>
        <div
          style={{
            padding: theme.spacing(2, 3),
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BasicButton label={t("next")} onClick={navigateNext} />
        </div>
      </Stack>
    </FullPageDialog>
  );
};
export default ChildrenListPage;
