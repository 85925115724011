import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Box from "components/Box";
import Chip from "components/Chip";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import FileUploadBox from "components/FileUploadBox";
import { CircularProgress } from "components/Loading";
import useMediaQuery from "components/useMediaQuery";

import { uploadAndParseDocument } from "entities/Documents/sdk";
import { useMyFamily, getMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { CheckIcon, AttentionIcon } from "icons";
import useIncomeEligibleFlowNavigation from "../hooks";

const VerifyChildCitizenshipPage = () => {
  const { childId: childIdParam, voucherId: vouchersIdParam } = useParams<{
    childId: string;
    voucherId: string;
  }>();
  const childId = parseInt(String(childIdParam));
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const { nextUrl, readyToNavigate, backUrl } =
    useIncomeEligibleFlowNavigation();

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const child = family?.children.find((child) => child.id === childId);
  const voucherId = vouchersIdParam ? parseInt(vouchersIdParam) : undefined;

  const childDocuments =
    family?.documents.filter(
      (document) =>
        document.child?.id === childId &&
        document.vouchers?.some((voucher) => voucher.id === voucherId)
    ) || [];

  const uploadedChildDocuments =
    childDocuments?.filter((document) =>
      document.parsing_types.includes("CHILD_CITIZENSHIP")
    ) || [];

  const allUploadedChildDocumentsAreParsed = uploadedChildDocuments.every(
    (document) => document.is_ready
  );

  const citizenshipVerifiedDocuments = childDocuments.filter(
    (document) => document.citizenship_verification
  );
  const citizenshipVerified = citizenshipVerifiedDocuments.length !== 0;

  if (!child || !family || !voucherId) {
    return null;
  }
  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    uploadAndParseDocument({
      childId,
      file,
      voucherId,
      parsingTypes: ["CHILD_CITIZENSHIP", "CHILD_AGE", "CHILD_RELATIONSHIP"],
    }).then(() => {
      setIsUploading(false);
      reloadFamily();

      const interval = setInterval(
        () =>
          getMyFamily().then(() => {
            if (allUploadedChildDocumentsAreParsed) {
              clearInterval(interval);
              reloadFamily();
            }
          }),
        2000
      );
    });
  };

  return (
    <FullPageDialog
      title={t("verifyChildInformation")}
      backUrl={backUrl}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text variant="bodyLarge" color={colors.onSurface}>
              {t("needToVerifyInfo")}
            </Text>
            <Text variant="titleMedium" color={colors.onSurfaceVariant}>
              <ul>
                <li>
                  {t("citizenshipOrLegalStatus")} {child.first_name}{" "}
                  {child.last_name}
                </li>
              </ul>
            </Text>
            <Text
              variant="titleMedium"
              sx={{ fontWeight: 600 }}
              color={colors.onSurfaceVariant}
            >
              {t("pleaseProvideValidDocumentation")} {child.first_name}{" "}
              {child.last_name}
            </Text>
            <Text variant="titleMedium">
              {t("commonExamplesOfDocumentation")}
            </Text>
            <Text variant="titleSmall" color={colors.onSurface}>
              {t("noPhotoID")}
            </Text>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                {t("uploadFor")} {child.first_name} {child.last_name}
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>

            {isUploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
              </div>
            )}

            {!allUploadedChildDocumentsAreParsed &&
              !citizenshipVerified &&
              !isUploading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress
                    thickness={8}
                    size={48}
                    sx={{ color: "#DA2C52" }}
                  />
                  <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
                    {t("verifyingDocuments")}
                  </Text>
                </div>
              )}

            {!citizenshipVerified &&
              allUploadedChildDocumentsAreParsed &&
              childDocuments.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AttentionIcon />
                  </div>
                  <Text color="error">{t("couldNotVerifyCitizenship")}</Text>
                </div>
              )}

            {!citizenshipVerified && (
              <FileUploadBox file={null} setFile={handleFileSelect} />
            )}
            {_.uniqBy(
              [...uploadedChildDocuments, ...citizenshipVerifiedDocuments],
              "id"
            ).map((document, index) => (
              <Box
                key={index}
                sx={{
                  padding: (theme) => theme.spacing(2, 0),
                  borderBottom: "1px solid #CFC8C3",
                }}
              >
                {document.file.original_file_name}
              </Box>
            ))}
            {citizenshipVerified && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text sx={{ fontWeight: "bold" }}>
                      {t("citizenshipVerified")}
                    </Text>
                  }
                  sx={{ backgroundColor: colors.tertiaryContainer }}
                />
              </div>
            )}
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(4, 1),
            alignItems: "center",
          }}
        >
          {readyToNavigate && (
            <Link to={nextUrl} style={{ width: matches ? "auto" : "100%" }}>
              <BasicButton label={t("next")} />
            </Link>
          )}
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default VerifyChildCitizenshipPage;
