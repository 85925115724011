import { forwardRef, useImperativeHandle } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import dayjs from "dayjs";

import Stack from "components/Stack";
import TextField from "components/TextField";
import FormHelperText from "components/FormHelperText";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import FormControl from "components/FormControl";
import Box from "components/Box";
import FormLabel from "components/FormLabel";
import StyledSelect from "components/StyledSelect";
import DateInput from "components/DateInput";

import { CheckIcon } from "icons";

import { phoneMask } from "utils/masks";
import { getDefaultLanguageValue } from "utils/language";
import { extractErrorMessage } from "utils/error";

import { colors } from "theme";

import {
  IVerifyInformationPrimaryContact,
  incomeEligiblePrimaryContactUpdate,
} from "entities/PrimaryContact/sdk";
import { useLanguageOptions } from "entities/FamilyMember/sdk";
import { PreferredContactMethod } from "entities/FamilyEdit/sdk";
import useIncomeEligibleFlowNavigation from "pages/VerifyIncomeFlow/hooks";

interface IProps {
  initialValues?: Partial<IVerifyInformationPrimaryContact>;
}

export interface VerifyPrimaryInformationRef {
  submitForm: () => void;
}

const PrimaryContactVerifyInformationForm = forwardRef<
  VerifyPrimaryInformationRef,
  IProps
>(({ initialValues }, ref) => {
  const { i18n, t } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const languageOptions = useLanguageOptions();

  const { navigateNext, readyToNavigate } = useIncomeEligibleFlowNavigation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IVerifyInformationPrimaryContact>({
    defaultValues: {
      first_name: initialValues?.first_name,
      last_name: initialValues?.last_name,
      phone_number: initialValues?.phone_number,
      date_of_birth: initialValues?.date_of_birth
        ? dayjs(initialValues?.date_of_birth).format("MM/DD/YYYY")
        : undefined,
      email: initialValues?.email,
      preferred_contact_method:
        initialValues?.preferred_contact_method || PreferredContactMethod.SMS,
      consent_text: initialValues?.consent_text,
      consent_email: initialValues?.consent_email,
      preferred_language: initialValues?.preferred_language,
    },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<IVerifyInformationPrimaryContact> = (data) => {
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;
    const primaryContactData = {
      ...data,
      date_of_birth: formattedBirthDate,
    };

    // TODO: Move submit out of form
    incomeEligiblePrimaryContactUpdate({ data: primaryContactData })
      .then(() => {
        if (readyToNavigate) {
          navigateNext();
        }
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error);
        toast.error(errorMessage);
      });
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  return (
    <form style={{ height: "100%", width: "100%" }}>
      <Stack
        sx={{ height: "100%" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Controller
          name="first_name"
          control={control}
          rules={{ required: t("fieldRequired") }}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t("firstName")}
              variant="outlined"
              margin="normal"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          rules={{ required: t("fieldRequired") }}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t("lastName")}
              variant="outlined"
              margin="normal"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="date_of_birth"
          control={control}
          rules={{ required: t("fieldRequired") }}
          render={({ field }) => (
            <DateInput
              {...field}
              control={control}
              label={t("dateOfBirth")}
              error={!!errors.date_of_birth}
              helperText={errors.date_of_birth?.message}
            />
          )}
        />
        <Controller
          name="phone_number"
          control={control}
          rules={{ required: t("fieldRequired") }}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t("phoneNumber")}
              variant="outlined"
              margin="normal"
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
              inputRef={(ref) => {
                field.ref(ref);
                phoneMask(ref);
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="consent_text"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t("textMessagesConsent")}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: t("fieldRequired"),
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: t("invalidEmail"),
            },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t("email")}
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="consent_email"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label={t("emailConsent")}
            />
          )}
        />
        {(errors.consent_text || errors.consent_email) && (
          <FormHelperText error>{t("consentRequired")}</FormHelperText>
        )}
        <FormControl
          component="fieldset"
          margin="normal"
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
            <FormLabel component="legend" style={{ padding: "0 10px" }}>
              {t("whichDoYouPrefer")}
            </FormLabel>
            <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
          </Box>
          <Controller
            name="preferred_contact_method"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleButtonGroup
                value={value}
                exclusive
                onChange={(newValue) => onChange(newValue)}
                fullWidth
                sx={{
                  "& .MuiToggleButtonGroup-grouped": {
                    margin: "8px 0",
                    border: `2px solid ${colors.outline}`,
                    textTransform: "capitalize",
                    justufyContent: "flex-start",
                    "&.Mui-selected": {
                      bgcolor: colors.secondaryContainer,
                      color: colors.onSecondaryContainer,
                      "&:hover": {
                        bgcolor: colors.onHoverSecondaryContainer,
                        color: colors.onSecondaryContainer,
                      },
                    },
                    "&:not(:first-of-type)": {
                      borderRadius: "0 50px 50px 0",
                    },
                    "&:first-of-type": {
                      borderRadius: "50px 0 0 50px",
                      borderRight: "1px solid",
                      borderColor: colors.outline,
                    },
                  },
                }}
              >
                <ToggleButton
                  value="SMS"
                  style={{
                    justifyContent: "flex-start",
                    paddingLeft: "30px",
                  }}
                >
                  {value == "SMS" && (
                    <CheckIcon
                      onClick={(e) => e.stopPropagation()}
                      style={{ marginRight: "15px" }}
                    />
                  )}

                  {t("textMessage")}
                </ToggleButton>
                <ToggleButton
                  value="MAIL"
                  style={{
                    justifyContent: "flex-start",
                    paddingLeft: "30px",
                  }}
                >
                  {value == "MAIL" && (
                    <CheckIcon
                      onClick={(e) => e.stopPropagation()}
                      style={{ marginRight: "15px" }}
                    />
                  )}

                  {t("email")}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </FormControl>
        <Controller
          name="preferred_language"
          control={control}
          defaultValue={defaultLanguage}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("preferredLanguage")}
              options={languageOptions}
              fullWidth
              margin="normal"
              error={!!errors.preferred_language}
              helperText={errors.preferred_language?.message}
            />
          )}
        />
      </Stack>
    </form>
  );
});

export default PrimaryContactVerifyInformationForm;
