import { URLS } from "config/urls";
import { useMyFamily } from "entities/Family/sdk";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { reverse } from "utils/urls";

const useIncomeEligibleFlowNavigation = ():
  | {
      readyToNavigate: false;
      backUrl: undefined;
      nextUrl: undefined;
      navigateBack: undefined;
      navigateNext: undefined;
    }
  | {
      readyToNavigate: true;
      backUrl: string;
      nextUrl: string;
      navigateBack: () => void;
      navigateNext: () => void;
    } => {
  const { data: family } = useMyFamily();
  const location = useLocation();
  const navigate = useNavigate();
  const { voucherId: voucherIdParam } = useParams<{ voucherId: string }>();
  const voucherId = voucherIdParam ? parseInt(voucherIdParam) : undefined;

  if (!voucherId) {
    return {
      readyToNavigate: false,
      backUrl: undefined,
      nextUrl: undefined,
      navigateBack: undefined,
      navigateNext: undefined,
    };
  }

  const voucher = family?.vouchers.find((v) => v.id === voucherId);
  console.log(voucher);

  const flow = [
    reverse(URLS.VERIFY_INCOME_PRIMARY_CONTACT, { voucherId }),
    reverse(URLS.VERIFY_INCOME_CHILD_CARE_NEEDS, { voucherId }),
    reverse(URLS.VERIFY_INCOME_OVERVIEW, { voucherId }),
    reverse(URLS.VERIFY_INCOME_PARENTS_LIST, { voucherId }),
    reverse(URLS.VERIFY_INCOME_CHILDREN_LIST, { voucherId }),
    reverse(URLS.VERIFY_INCOME_DEPENDENTS_LIST, { voucherId }),
    ...(family?.family_members.map((fm) =>
      reverse(URLS.VERIFY_INCOME_VERIFICATION_FOR_PARENT, {
        voucherId,
        parentId: fm.id,
      })
    ) || []),
    ...(family?.children
      .map((c) => [
        reverse(URLS.VERIFY_INCOME_CITIZENSHIP_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
        reverse(URLS.VERIFY_INCOME_RELATIONSHIP_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
        reverse(URLS.VERIFY_INCOME_AGE_FOR_CHILD, {
          voucherId,
          childId: c.id,
        }),
      ])
      .flat() || []),
    reverse(URLS.VERIFY_INCOME_RESIDENCY_VERIFICATION, { voucherId }),
    reverse(URLS.VERIFY_INCOME_MILITARY_OR_RETIRED, { voucherId }),
    ...(family?.family_members.map((fm) =>
      reverse(URLS.VERIFY_PARENT_ELIGIBILITY, {
        voucherId,
        parentId: fm.id,
      })
    ) || []),

    ...(family?.family_members
      .map((fm) => [
        reverse(URLS.VERIFY_INCOME_PARENT_OTHER_INCOME, {
          voucherId,
          parentId: fm.id,
        }),
        reverse(URLS.VERIFY_INCOME_PARENT_RENTAL_INCOME, {
          voucherId,
          parentId: fm.id,
        }),
        reverse(URLS.VERIFY_INCOME_PARENT_LOTTERY, { voucherId, parentId: fm.id }),
        reverse(URLS.VERIFY_INCOME_PARENT_ALIMONY, { voucherId, parentId: fm.id }),
        reverse(URLS.VERIFY_INCOME_PARENT_WORKERS_COMPENSATION, {
          voucherId,
          parentId: fm.id,
        }),
        reverse(URLS.VERIFY_INCOME_PARENT_UNEMPLOYMENT, {
          voucherId,
          parentId: fm.id,
        }),
        reverse(URLS.VERIFY_INCOME_PARENT_RETIREMENT, {
          voucherId,
          parentId: fm.id,
        }),
      ])
      .flat() || []),
  ];

  const currentStepIndex = flow.findIndex((f) => f === location.pathname);
  const backUrl = flow[currentStepIndex - 1];
  const nextUrl = flow[currentStepIndex + 1];

  const navigateBack = () => {
    return navigate(backUrl);
  };

  const navigateNext = () => {
    return navigate(nextUrl);
  };

  return {
    readyToNavigate: true,
    navigateBack,
    navigateNext,
    backUrl,
    nextUrl,
  };
};

export default useIncomeEligibleFlowNavigation;
